import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {pagesPath} from 'lib/$path';
import NextLink from 'next/link';
import {useTranslation} from 'next-i18next';
import {CreateOrganizationDialog} from 'features/org/mutation/create/CreateOrganizationDialog';
import {MdAdd, MdArrowDropDown, MdGroups} from 'react-icons/md';
import {useSideNav} from './sideNav/store';
import {OrgSwitcherViewerQueryFragment} from 'lib/gql/generated';
import {decodeId} from 'common/convertId';
import {Env} from 'env/env';
import {useQueryParamOrgId} from 'features/org/store';
import {filterIsNotNull} from 'common/utils';

type Props = {
  data: OrgSwitcherViewerQueryFragment;
  stretch?: boolean;
};
export const OrgSwitcher = ({data, stretch}: Props) => {
  const {orgDatabaseId} = useQueryParamOrgId();

  const orgs = filterIsNotNull(data.viewer?.organizations ?? []).map(o => ({
    name: o.organization?.name ?? '',
    databaseId: o.organization?.id ? decodeId(o.organization.id).docId : '',
  }));
  const currentOrg = orgs?.find(o => o.databaseId === orgDatabaseId);

  return (
    <OrgSwitcherPresentation
      orgs={orgs}
      currentOrg={currentOrg}
      stretch={stretch}
    />
  );
};

type PresentationProps = {
  currentOrg?: {
    name: string;
    databaseId: string;
  } | null;
  orgs: {
    databaseId: string;
    name: string;
  }[];
  stretch?: boolean;
};

const getHref = (orgId: string) => {
  return pagesPath.orgs._orgId(orgId).$url();
};

export const OrgSwitcherPresentation = ({
  currentOrg,
  orgs,
  stretch,
}: PresentationProps) => {
  const {isOpen: globalNavOpen} = useSideNav();
  const {t} = useTranslation();
  if (!orgs.length) return null;

  return (
    <Menu>
      <Tooltip label={t('general.ワークスペースを変更する')}>
        <MenuButton
          tabIndex={globalNavOpen ? 0 : -1}
          as={Button}
          variant="ghost"
          textAlign="left"
          leftIcon={<MdGroups size="18px" />}
          rightIcon={<MdArrowDropDown size="18px" />}
          flex={stretch ? 1 : undefined}
        >
          <Box overflow="hidden" textOverflow="ellipsis" fontSize="text2">
            {currentOrg?.name}
          </Box>
        </MenuButton>
      </Tooltip>

      <Portal>
        <MenuList zIndex="overlay">
          {orgs.map(org => (
            <NextLink
              href={getHref(org.databaseId)}
              passHref
              key={org.databaseId}
              legacyBehavior
            >
              <MenuItem
                color={
                  currentOrg?.databaseId === org.databaseId
                    ? 'amethyst.prior'
                    : 'color.regular'
                }
                fontWeight={
                  currentOrg?.databaseId === org.databaseId ? 'bold' : 'regular'
                }
                as="a"
                whiteSpace="nowrap"
                overflow="hidden"
                display="block"
                textOverflow="ellipsis"
              >
                {org.name}
              </MenuItem>
            </NextLink>
          ))}
          <CreateOrgMenuItem />
        </MenuList>
      </Portal>
    </Menu>
  );
};

const CreateOrgMenuItem = () => {
  const {t} = useTranslation();
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <MenuItem
        onClick={onOpen}
        fontWeight="bold"
        isDisabled={Env.ENV === 'demo'}
      >
        <MdAdd size="24px" />
        {t('general.ワークスペースを作成する')}
      </MenuItem>

      <CreateOrganizationDialog isOpen={isOpen} onClose={onClose} />
    </>
  );
};
